import React ,{ Component }from "react";
import { FaBars, FaBuffer, FaServicestack } from "react-icons/fa";
import { FiCast , FiLayers , FiUsers , FiMonitor, FiPhone, FiSmartphone, FiServer, FiCloudLightning, FiWatch, FiTerminal } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiSmartphone />,
        title: 'Mobile App Development',
        description: 'When it comes to native IOS development, speed, performance and smooth user epxerience is my first priority. Userfriendly elegent design packed with smooth animations to keep users focused on application.'
    },
    {
        icon: <FiServer />,
        title: 'Backend Development',
        description: 'Backend is the most important part of the application when it comes to features, optimization and security. I develop backend with new technologies, reliable and fast technologies i.e NodeJS, MongoDB and Express.        '
    },
    {
        icon: <FiTerminal />,
        title: 'Emerging Technologies',
        description: 'Technology has changed the world. Touch ID, Face Recognisation, IoT, Smartwatches, VR, AR has become major game changer and i am here to provide all these enhancements to your business.'
    },
    { 
        icon: <FiWatch />,
        title: 'Watch App Development',
        description: 'Smart watches are great way to make you fit. Heart rate moniter, motion detection, face and hanshake detection can help to stay active, smart and fit. I have worked with all these features.'
    },
    {
        icon: <FiLayers />,
        title: 'Full Stack Development',
        description: 'I can transform ideas into highly successful technology products that optimize your business processes and boost growth. Modern applications with universal devices compatibility. Application looks awesome even on small devices, mobiles and ipads'
    },
    { 
        icon: <FiUsers />,
        title: 'Client Relations',
        description: "I have worked with dozens of clients of different regions. I am invloved in client meetins on daily basis. I get instantly what client wants and how to deliver projects and meet client's expectations."
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row service-main-wrapper">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a href="/service-details">
                                <div className="service service__style--2 text-left bg-gray">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
